* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

html {
  scroll-behavior: smooth;
}

a {
  display: block;
  text-decoration: none;
}

li {
  list-style-type: none;
}

.fg {
  animation: slide 3s infinite alternate-reverse linear;
}

@keyframes slide {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}

.bradcrumb {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  animation: run 2s infinite linear alternate-reverse;
}
@keyframes run {
  0% {
    left: 0%;
  }
  100% {
    left: 98%;
  }
}

/*  ==================== location ======================== */
.contact-info-box {
  background-color: #fff;
  padding: 30px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  transition: 0.5s;
}
.contact-info-box .contact-icon {
  text-align: center;
  background: #f7fafd;
  border-radius: 50%;
  color: #0086ff;
  font-size: 30px !important;
  height: 65px;
  line-height: 62px;
  transition: 0.5s;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-info-box:hover .contact-icon {
  background-color: #0086ff;
  color: white;
}
.contact-info-box:hover {
  transform: translateY(-10px);
}




/*  *************** slider ****************** */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
}
.owl-carousel .owl-nav button span{
  background-color: #d6d6d6 !important;
  padding: 15px !important;
  font-size: 25px !important;
}
.owl-carousel .owl-nav .owl-prev{
  position: absolute;
  top: 50%;
  left: 5%;
}
.owl-carousel .owl-nav .owl-next{
  position: absolute;
  top: 50%;
  right: 5%;
}
.placement-company img {
  width: 100%;
  padding: 20px;
  aspect-ratio: 4/2;
}
.nodeJs_resume__mnTyb input {
  font-size: 15px;
  height: 45px;
  width: 160px;
  outline: none!important;
  color: transparent;
  opacity: 1;
}
.nodeJs_customImageInput__f58b9:before {
  content: "Upload Resume/CV";
  display: block;
  color: #35b8fc;
  background: #fff;
  border: 1px solid #35b8fc;
  border-radius: 3px;
  padding: 7px 8px;
  outline: none!important;
  white-space: nowrap;
  font-size: 14px;
  width: 100%;
  margin: 8px 0;
  text-align: center;
}
.react-tel-input .selected-flag {
  width: 45px!important;
}
.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border: 1px solid #35b8fc!important;
  height: 42px!important;
}


/* about */

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 40px !important;
}